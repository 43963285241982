.main{
    height: 500px;
    background-image: url('../../assets/img/law.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
}
.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
}
h1{
    text-align: center;
    color: #fff;
    font-size: 54px;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}

.button{
    border-radius: 6px;
    background-color: #fff;
    border: 2px solid #005180;
    padding: 8px 25px;
    color: #005180;
}
.button:hover{
    background-color: #005180;
    border: 2px solid #005180;
    padding: 8px 20px;
    color: #fff;
    box-shadow: #005180 0px 7px 29px 0px;
    transition: .4s ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 790px){
    h1{
        font-size: 38px;
    }
}
@media screen and (max-width: 490px){
    h1{
        font-size: 28px;
    }
}