.block{
    max-width: 20%;
    min-width: 18%;
    padding: 20px;
    border-radius: 25px;
    box-shadow: #D9D9D9 0px 5px 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.p{
    text-align: left;
}
.block img{
    width: 50px;
}

@media (max-width:990px) {
    .block{
        min-width: 30%;
        margin-top: 10px;
    }
}
@media (max-width:790px) {
    .block{
        max-width: 50%;
    }
}
@media (max-width:490px) {
    .block{
        max-width: 70%;
    }
}