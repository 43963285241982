.block{
    max-width: 40%;
    padding: 20px;
    margin: 20px;
    border-radius: 25px;
    box-shadow: #005180 0px 5px 17px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
}
.block a{
    margin: 0 auto;
}
h4{
    text-align: left;
}
.subtitle{
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #9E9E9E;
}
.btn{
    margin: 20px;
    padding: 8px 60px;
    border: none;
    background-color: #005180;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.btn:hover{
    box-shadow: #005180 0px 5px 17px;
    transition: .2s ease-in-out;
}
@media (max-width:790px) {
    .block{
        max-width: 60%;
    }
}
@media (max-width:490px) {
    .block{
        max-width: 80%;
    }
}