footer{
    background-color: #fff;
}
.container{
    max-width: 1140px;
    margin: 0 auto;
}
.block{
    padding: 15px 0;
    display: flex;
    justify-content: space-between;

}
.left{
    width: 40%;
    display: flex;
    justify-content: space-between;
}
.title{
    color: #005180;
    font-size: 18px;
    margin: 0;padding: 0;
}
.subtitle{
    text-align: start;
    color: #9E9E9E;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
}
.icon{
    display: flex;
    
}
.left{
    display: flex;
}
.links{
    width: 200px;
}
.contact{
    width: 250px;
}
.info{
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
.icons{
    cursor: pointer;
    color: #9E9E9E;
    padding-right: 10px;
    font-size: 24px;
}
.links a{
    color: #9E9E9E;
    text-decoration: none;
    font-size: 14px;
}
.links a:hover{
    color: #9E9E9E;
    text-decoration:underline;
}
@media screen and (max-width: 890px){
    .block{
        display: block;
        padding-left: 25px;    }
    .left{
        margin-top: 15px;
        width: 80%;
    }
}
@media screen and (max-width: 490px){
    .left{
        display: block;
    }
    .contact{
        margin-top: 10px;
    }
}