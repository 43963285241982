.header{
    background-color: #fff;
}
.container{
    max-width: 1140px;
    margin: 0 auto;
}
.top{
    background-color: #fff;
}
.intro{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 26px;
    color: #005180;
}
.icons{
    cursor: pointer;
    color: #9E9E9E;
}


.bottom{
    height: 30px;
    background-color: #005180;
    color: #fff;
}
.links{
    height: 30px;
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.links a{
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}
.links a:hover{
    color: #fff;
    text-decoration:underline;
}

.menu__btn{
    display: none;
    position: relative;
    width: 25px;
    height: 20px;
    padding: 0 6px;
    cursor: pointer;
}
.menu__btn::after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 25px;
    height: 1.5px;
    background-color: #fff;
}
.senond{
    width: 25px;
    height: 1.5px;
    background-color: #fff;
    top: 10px;
    position: absolute;
}
.menu__btn::before{
    content: '';
    position: absolute;
    top: 0;
    width: 25px;
    height: 1.5px;
    background-color: #fff;
}

@media screen and (max-width: 890px){
    .left{
        padding-right: 5px;
        width: 20%;
    }
    .bottom{
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .menu__btn{
        display: block;
    }
    .links{
        display: none;
    }
}
@media screen and (max-width: 420px){
    .left{
        width: 32%;
    }
}