.partners {
  display: flex;
  justify-content: center;
  color: #005180;
  padding: 40px 0;
  background-color: #D9D9D9;
}

.container{
  max-width: 80%;
}

.hrc{
  margin-top: 0;
  background-color: #005180;
}

.partners__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 767px) {
  .partners__items {
    margin-top: calc(
 30px + 102 * ((100vw - 320px) / 1620));
  }
}

@media (min-width: 767px) {
  .partners__items {
    margin-top: calc(30px + 60 * (100vw / 1620));
  }
}

.partners__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 767px) {
  .partners__item {
    margin-bottom: calc(
 20px + 68 * ((100vw - 320px) / 1620));
  }
}

@media (min-width: 767px) {
  .partners__item {
    margin-bottom: calc(20px + 40 * (100vw / 1620));
  }
}

@media (any-hover: hover) {
  .partners__item:hover img {
    -webkit-transform: scale(1.2) rotate(10deg);
            transform: scale(1.2) rotate(10deg);
  }
}

.partners__img {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  overflow: hidden;
}

.partners__img img {
  width: 100%;
  -webkit-transition: all ease 500ms 0s;
  transition: all ease 500ms 0s;
}

