.home{
    min-height: calc(100vh - 220px);
}
.container{
    max-width: 1140px;
    margin: 0 auto;
}
.content{
    background-color: #005180;

}
.pros{
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.pross{
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.services{
    padding: 20px 0;
    background-color: #fff;
    text-align: center;
}
.block{
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.recall{
    padding: 20px 0;
    background-color: #fff;
    text-align: center;

}


@media (max-width:790px) {
    .pros{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .block{
        display: flex;
        justify-content: space-around;
    }
}
@media (max-width:990px) {
    .pross{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

}
