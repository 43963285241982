.about{
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #fff;
    margin: 0 auto;
}
.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}
hr{
    width: 150px;
    height: 5px;
    background-color: #005180
}
p{
    max-width: 800px;
    text-align: center;
}