.services{
    min-height: calc(100vh - 220px);
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #fff;
    margin: 0 auto;
}
.container{
    max-width: 1040px;
    margin: 0 auto;
}
h2{
    text-align: center;
}

hr{
    width: 150px;
    height: 5px;
    background-color: #005180
}
.items{
    padding: 30px 0;
    border-bottom: 1px solid #9E9E9E;
}
.subtitle{
    color: #9E9E9E; 
    max-width: 900px;
}
h4{
    padding-bottom: 15px;
}
li{
    padding-bottom: 10px;
}
h5{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding-left: 20px;
    padding-bottom: 5px;
}
@media (max-width:790px) {
    .items{
        // margin: 0 15px;
        padding: 30px 20px;
    }
    .block{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
