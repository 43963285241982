.menu{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
    transform: translateX(-130%);
    transition: all 0.2s;
}
.menu.active{
    transform: translateX(0);
}
.blur{
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    position: absolute;
}
.menu__content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .9;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.menu__header{
    width: 100%;
    color: #005180;
    font-size: 2rem;
    padding: 10px;
    border-bottom: 1px solid #005180;
    text-align: center;
}
ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
}
ul li a{
    color: #000;
    text-decoration: none;
}
ul li a:hover{
    color: #4B4136;
    text-decoration: underline;
}
ul{
    text-align: center;
    list-style-type: none;
}