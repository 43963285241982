.contact{
    background-color: #005180;
    text-align: center;
    color: #fff;
    padding: 40px 0;
}
.container{
    max-width: 1140px;
    margin: 0 auto;
}
.info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.email{
    margin-left: 10px;
    margin-top: 10px;
}
h4{
    margin: 0;
    font-size: 22px;
}
.contacts{
    max-width: 400px;
    text-align: left;
}
.items{
    display: flex;
    align-items: flex-start;
    justify-content: start;
    margin-bottom: 15px;
}
.hrc{
    margin-top: 0;
    background-color: #fff;
}
.map{
    width: 520px;
    height: 400px;
    border: 0;
}
.spans{
    display: flex;
    flex-direction: column;
}
.spans span{
    padding-top: 5px;
}
@media (max-width:1090px) {
    .info{
        margin: 0 15px;
    }
    .map{
        width: 400px;
    }
}
@media (max-width:990px) {
    .map{
        width: 700px;
    }
}
@media (max-width:790px) {
    .map{
        width: 600px;
    }
}
@media (max-width:690px) {
    .map{
        width: 480px;
    }
}
@media (max-width:490px) {
    .map{
        width: 300px;
    }
}