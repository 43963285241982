.hr{
    width: 70px;
    height: 2px;
    background-color: #fff;
}
.block{
    margin-top: 10px;
    width: 250px;
    border-color: aqua;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}
.subtitle{
    width: 230px;
    color: #bcbbbb;
}